import React from "react";
import rules from '../img/rules.jpg'
import { Box, Typography } from "@mui/material";

const RulesPage = (props) => {

    return (
        <Box className="flex flex-col justify-center spacce-y-4">
            <img src={rules} alt="Rules hero"  className="md:w-1/3 mx-auto"/>
            <Typography 
                variant="h2" 
                className="text-center">
                Rules
            </Typography>
            <Typography>
                The goal of NaMoPaiMo is to encourage, empower and educate hobby artists of all skill levels. Participants may use the event in whatever way best suits their needs. That said, there are some rules for people who want to be an official part of NaMoPaiMo.
            </Typography>
            <ol class="list-decimal pl-20">
                <li>
                    Participants must sign up before midnight on January 31. Entrants will be required to submit a photo of their unpainted model at the time of registration.
                </li>
                <li>
                    Participants must paint one three dimensional model equine. There are no scale or medium requirements, but medallions and models of other animals are not permissible.
                </li>
                <li>
                    Models may be customized, prepped and primed before the start of NaMoPaiMo. However, the entirety of the finishwork must be completed between 12:01 AM February 1 and 11:59 PM February 28.
                </li>
                <li>
                    To complete the challenge, one photo of the finished model must be uploaded to the NaMoPaiMo website using the completion code you received at registration.
                </li>
            </ol>
        </Box>
    );
}

export default RulesPage;