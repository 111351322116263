import { Box, Button, Dialog, DialogContent, DialogTitle, Modal, Typography } from "@mui/material";
import React from "react";

const CompletedModal = (props) => {

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}>
            <Box>
                <DialogTitle>
                    Registration complete!
                </DialogTitle>
                <DialogContent>
                    Your completion code is <b>{props.code}</b>. You will also receive an email confirmation with code. This code will be required to complete your entry. Please do not lose it!
                </DialogContent>
                <Button
                    onClick={props.onClose}
                    fullWidth>
                    OK
                </Button>
            </Box>
        </Dialog>
    );
}

export default CompletedModal;